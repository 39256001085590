import { Button } from '@chakra-ui/button'
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/menu'
import { Tooltip } from '@chakra-ui/tooltip'
import React, { useContext } from 'react'
import { AiOutlineFileAdd } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { Verifycontext } from '../../lib/Providers/LoginVeification'
import { HiOutlineViewGridAdd } from "react-icons/hi";
import { Box, Center, Flex, Icon, IconButton, SimpleGrid, Text, VStack } from '@chakra-ui/react'
import Addproject, { AddClientSVG, AddDepartmentSVG, AddEmployeeSVG, AddprojectSVG } from '../../assets/svg/Addproject'

export const ModernCreatemenu = () => {
    const navigate = useNavigate();
    const { admin_access, manager_access, superviser_access } = useContext(Verifycontext);
    return (
        <>
            {(admin_access || manager_access || superviser_access) &&
                <>
                    <Menu>
                        <Tooltip fontSize='sm' label="Create Entity">
                            {/* <IconButton color='primary.500' aria-label='Search database' icon={<AiOutlineEdit />} /> */}
                            {/* <AiOutlineEdit color='primary.500' cursor='pointer' /> */}
                            <MenuButton
                                // height={8}
                                _active={{ bg: 'none' }}
                                bg='none'
                                _hover={{ bg: 'none' }}
                                color='moderntheme.500'
                                // border='1px'
                                // borderColor='primary.500'
                                as={Button}
                                px={0}
                            // leftIcon={<AiOutlineFileAdd />}
                            >
                                {/* Create */}
                                <IconButton as={HiOutlineViewGridAdd}
                                    _hover={{ bg: 'moderntheme.100' }}
                                    rounded="full"
                                    bg='none'
                                    w={5} h={5}
                                    color='moderntheme.500' />
                            </MenuButton>
                        </Tooltip>
                        <MenuList p={'0'} fontSize={13} minW='8rem'>
                            {admin_access &&
                                <>
                                    <SimpleGrid columns={2}>
                                        {/* <MenuItem minH='35px' onClick={() => { navigate('/add-role') }}>
                                        <span>Add Role</span>
                                    </MenuItem> */}
                                        <MenuItem minH='35px' onClick={() => { navigate('/add-project') }}>
                                            <Center width='100%'>
                                                <Box textAlign={'center'}>
                                                    <Flex justifyContent={'center'} mb={'2'} >
                                                        <AddprojectSVG />
                                                    </Flex >
                                                    <Text> Add Project</Text>
                                                </Box>
                                            </Center>
                                        </MenuItem>
                                        <MenuItem minH='35px' onClick={() => { navigate('/add-client') }}>
                                            <Center width='100%'>
                                                <Box textAlign={'center'}>
                                                    <Flex justifyContent={'center'} mb={'2'} >
                                                        <AddClientSVG />
                                                    </Flex >
                                                    <Text> Add Client</Text>
                                                </Box>
                                            </Center>
                                        </MenuItem>
                                        <MenuItem minH='35px' onClick={() => { navigate('/add-department') }}>
                                            <Center width='100%'>
                                                <Box textAlign={'center'}>
                                                    <Flex justifyContent={'center'} mb={'2'} >
                                                        <AddDepartmentSVG />
                                                    </Flex >
                                                    <Text> Add Department</Text>
                                                </Box>
                                            </Center>
                                        </MenuItem>
                                        <MenuItem minH='35px' onClick={() => { navigate('/add-employee') }}>
                                            <Center width='100%'>
                                                <Box textAlign={'center'}>
                                                    <Flex justifyContent={'center'} mb={'2'} >
                                                        <AddEmployeeSVG />
                                                    </Flex >
                                                    <Text> Add Employee</Text>
                                                </Box>
                                            </Center>
                                        </MenuItem>
                                    </SimpleGrid>
                                </>
                            }
                            {
                                manager_access &&
                                <>
                                    <SimpleGrid columns={1} >
                                        <MenuItem minH='35px' onClick={() => { navigate('/add-employee') }}>
                                            <Center width='100%'>
                                                <Box textAlign={'center'}>
                                                    <Flex justifyContent={'center'} mb={'2'} >
                                                        <AddEmployeeSVG />
                                                    </Flex >
                                                    <Text> Add Employee</Text>
                                                </Box>
                                            </Center>
                                        </MenuItem>
                                        <MenuItem minH='35px' onClick={() => { navigate('/add-project') }}>
                                            <Center width='100%'>
                                                <Box textAlign={'center'}>
                                                    <Flex justifyContent={'center'} mb={'2'} >
                                                        <AddprojectSVG />
                                                    </Flex >
                                                    <Text> Add Project</Text>
                                                </Box>
                                            </Center>
                                        </MenuItem>
                                        <MenuItem minH='35px' onClick={() => { navigate('/add-client') }}>
                                            <Center width='100%'>
                                                <Box textAlign={'center'}>
                                                    <Flex justifyContent={'center'} mb={'2'} >
                                                        <AddClientSVG />
                                                    </Flex >
                                                    <Text> Add Client</Text>
                                                </Box>
                                            </Center>
                                        </MenuItem>
                                    </SimpleGrid>
                                </>
                            }
                            {
                                superviser_access &&

                                <MenuItem minH='35px' onClick={() => { navigate('/add-employee') }}>
                                    <Center width='100%'>
                                        <Box textAlign={'center'}>
                                            <Flex justifyContent={'center'} mb={'2'} >
                                                <AddEmployeeSVG />
                                            </Flex >
                                            <Text> Add Employee</Text>
                                        </Box>
                                    </Center>
                                </MenuItem>
                            }
                        </MenuList>
                    </Menu>
                </>
            }
        </>
    )
}
