import { Box, Text } from '@chakra-ui/react'
import React from 'react'

export const AddprojectSVG = () => {
    return (
        <>
            <svg width="32" height="37" viewBox="0 0 112 129" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M66.5 126H10C6.13401 126 3 122.866 3 119V10C3 6.13401 6.13401 3 10 3H101C104.866 3 108 6.13401 108 10V79.5" stroke="#00b4b4" stroke-width="6" stroke-linecap="round" />
                <rect x="111.5" y="103.5" width="6" height="44" rx="3" transform="rotate(90 111.5 103.5)" fill="#00b4b4" />
                <rect x="111.5" y="103.5" width="6" height="44" rx="3" transform="rotate(90 111.5 103.5)" fill="#00b4b4" />
                <rect x="86" y="84" width="6" height="44" rx="3" fill="#00b4b4" />
                <rect x="86" y="84" width="6" height="44" rx="3" fill="#00b4b4" />
                <path d="M57.492 34.1C64.858 34.1 70.339 35.376 73.935 37.928C77.531 40.48 79.329 45.12 79.329 51.848C79.329 57.474 77.589 62.172 74.109 65.942C70.629 69.654 65.061 71.51 57.405 71.51C54.911 71.51 52.939 71.481 51.489 71.423L51.315 86.735L57.666 87.431C57.666 89.983 56.999 92.506 55.665 95H34.176L33.393 90.215C36.409 88.997 38.845 88.156 40.701 87.692C40.701 87.518 40.44 72.177 39.918 41.669C38.99 41.437 36.583 40.683 32.697 39.407L33.393 34.1H57.492ZM55.578 63.245C59.464 63.187 62.48 62.114 64.626 60.026C66.772 57.938 67.845 55.241 67.845 51.935C67.845 48.629 66.888 46.28 64.974 44.888C63.118 43.438 60.189 42.713 56.187 42.713C54.621 42.713 53.142 42.742 51.75 42.8C51.634 51.964 51.576 58.808 51.576 63.332L55.578 63.245Z" fill="#00b4b4" />
            </svg></>
    )
}


export const AddDepartmentSVG = () => {
    return (
        <svg width="32" height="37" viewBox="0 0 112 129" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M66.5 126H10C6.13401 126 3 122.866 3 119V10C3 6.13401 6.13401 3 10 3H101C104.866 3 108 6.13401 108 10V79.5" stroke="#00b4b4" stroke-width="6" stroke-linecap="round" />
            <rect x="111.5" y="103.5" width="6" height="44" rx="3" transform="rotate(90 111.5 103.5)" fill="#00b4b4" />
            <rect x="111.5" y="103.5" width="6" height="44" rx="3" transform="rotate(90 111.5 103.5)" fill="#00b4b4" />
            <rect x="86" y="84" width="6" height="44" rx="3" fill="#00b4b4" />
            <rect x="86" y="84" width="6" height="44" rx="3" fill="#00b4b4" />
            <path d="M85.245 63.245C85.245 76.005 82.2 84.879 76.11 89.867C73.616 91.839 70.919 93.202 68.019 93.956C65.119 94.652 61.32 95 56.622 95H34.089L33.306 90.215C37.714 88.591 40.15 87.75 40.614 87.692C40.614 87.518 40.353 72.177 39.831 41.669C37.279 41.031 34.872 40.277 32.61 39.407C32.958 36.391 33.19 34.622 33.306 34.1H56.361C62.045 34.1 66.569 34.68 69.933 35.84C73.355 37 76.371 39.059 78.981 42.017C83.157 46.889 85.245 53.965 85.245 63.245ZM57.405 86.561C64.017 86.561 68.396 84.966 70.542 81.776C72.746 78.586 73.848 73.076 73.848 65.246C73.848 57.416 72.63 51.732 70.194 48.194C67.758 44.598 63.698 42.8 58.014 42.8H51.663C51.431 62.346 51.286 76.933 51.228 86.561H57.405Z" fill="#00b4b4" />
        </svg>
    )
}


export const AddClientSVG = () => {
    return (
        <svg width="32" height="37" viewBox="0 0 112 129" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M66.5 126H10C6.13401 126 3 122.866 3 119V10C3 6.13401 6.13401 3 10 3H101C104.866 3 108 6.13401 108 10V79.5" stroke="#00b4b4" stroke-width="6" stroke-linecap="round" />
            <rect x="111.5" y="103.5" width="6" height="44" rx="3" transform="rotate(90 111.5 103.5)" fill="#00b4b4" />
            <rect x="111.5" y="103.5" width="6" height="44" rx="3" transform="rotate(90 111.5 103.5)" fill="#00b4b4" />
            <rect x="86" y="84" width="6" height="44" rx="3" fill="#00b4b4" />
            <rect x="86" y="84" width="6" height="44" rx="3" fill="#00b4b4" />
            <path d="M71.499 53.762L68.802 53.675L66.279 43.235C62.915 42.655 60.392 42.365 58.71 42.365C55.404 42.365 51.692 43.032 47.574 44.366C46.356 46.628 45.399 49.528 44.703 53.066C44.007 56.604 43.659 59.939 43.659 63.071C43.659 79.079 48.27 87.083 57.492 87.083C61.436 87.083 64.568 86.503 66.888 85.343L68.715 74.729C72.369 74.729 75.153 74.932 77.067 75.338C77.995 81.66 78.459 86.793 78.459 90.737C77.067 91.781 74.254 92.941 70.02 94.217C65.844 95.493 61.726 96.131 57.666 96.131C40.382 96.131 31.74 85.459 31.74 64.115C31.74 53.965 33.828 46.251 38.004 40.973C42.238 35.695 48.966 33.056 58.188 33.056C62.77 33.056 66.83 33.723 70.368 35.057C73.5 36.217 76.168 37.493 78.372 38.885C78.372 42.829 77.937 47.585 77.067 53.153C75.443 53.559 73.587 53.762 71.499 53.762Z" fill="#00b4b4" />
        </svg>
    )
}

export const AddEmployeeSVG = () => {
    return (
        <svg width="32" height="37" viewBox="0 0 112 129" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M66.5 126H10C6.13401 126 3 122.866 3 119V10C3 6.13401 6.13401 3 10 3H101C104.866 3 108 6.13401 108 10V79.5" stroke="#00b4b4" stroke-width="6" stroke-linecap="round" />
            <rect x="111.5" y="103.5" width="6" height="44" rx="3" transform="rotate(90 111.5 103.5)" fill="#00b4b4" />
            <rect x="111.5" y="103.5" width="6" height="44" rx="3" transform="rotate(90 111.5 103.5)" fill="#00b4b4" />
            <rect x="86" y="84" width="6" height="44" rx="3" fill="#00b4b4" />
            <rect x="86" y="84" width="6" height="44" rx="3" fill="#00b4b4" />
            <path d="M51.663 42.8C51.663 49.412 51.605 55.096 51.489 59.852H60.363L61.233 53.849C64.191 53.849 67.062 54.574 69.846 56.024V73.424L64.104 73.685C62.712 71.597 61.9 69.77 61.668 68.204H51.402C51.286 76.324 51.228 82.443 51.228 86.561H66.105L68.019 78.992C71.615 78.992 74.341 79.166 76.197 79.514C77.067 82.82 77.502 85.836 77.502 88.562C77.502 91.23 77.415 93.376 77.241 95H34.089L33.306 90.215C37.076 88.765 39.512 87.924 40.614 87.692C40.556 84.27 40.498 79.456 40.44 73.25C40.208 59.33 40.005 48.803 39.831 41.669L32.61 39.407L33.306 34.1H76.023C76.197 35.666 76.284 37.638 76.284 40.016C76.284 42.336 75.849 45.584 74.979 49.76C73.181 50.34 70.455 50.63 66.801 50.63L64.8 42.8H51.663Z" fill="#00b4b4" />
        </svg>
    )
}
export const NoEmployee = () => {
    return (
        <svg width="80" height="100" viewBox="0 0 121 167" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M31.25 32H7C4.23858 32 2 34.2386 2 37V160C2 162.761 4.23858 165 7 165H114C116.761 165 119 162.761 119 160V37C119 34.2386 116.761 32 114 32H89.75" stroke="#00B4B4" stroke-width="3" />
            <path d="M46 18.7422H35C32.7909 18.7422 31 20.533 31 22.7422V39.7422C31 41.9513 32.7909 43.7422 35 43.7422H87C89.2091 43.7422 91 41.9513 91 39.7422V22.7422C91 20.533 89.2091 18.7422 87 18.7422H77" stroke="#00B4B4" stroke-width="3" stroke-linecap="round" />
            <path d="M44.0605 18.954C43.8567 16.5092 44.1692 14.0488 44.9777 11.7326C45.7862 9.41635 47.0727 7.29598 48.7537 5.50909C50.4347 3.72221 52.4726 2.30872 54.7352 1.36038C56.9978 0.412027 59.4345 -0.0499978 61.8872 0.0042842C64.3399 0.0585662 66.7538 0.627942 68.9722 1.67546C71.1906 2.72298 73.164 4.22524 74.7643 6.08475C76.3646 7.94426 77.556 10.1195 78.2613 12.4692C78.9666 14.819 79.1699 17.2907 78.8581 19.7241L75.905 19.3457C76.1637 17.3263 75.995 15.2751 75.4097 13.3251C74.8244 11.3751 73.8357 9.56997 72.5077 8.02681C71.1796 6.48366 69.542 5.23698 67.701 4.36768C65.86 3.49837 63.8568 3.02586 61.8213 2.98081C59.7859 2.93577 57.7637 3.31919 55.8861 4.1062C54.0084 4.8932 52.3172 6.06621 50.9222 7.5491C49.5272 9.03198 48.4596 10.7916 47.7886 12.7138C47.1176 14.636 46.8583 16.6778 47.0275 18.7066L44.0605 18.954Z" fill="#00B4B4" />
            <path d="M76.3076 71.2832H50.5934C46.4213 71.2832 43.0391 74.6654 43.0391 78.8376V83.9002C43.0391 88.0723 46.4213 91.4545 50.5934 91.4545H76.3076C80.4798 91.4545 83.862 88.0723 83.862 83.9002V78.8376C83.862 74.6654 80.4798 71.2832 76.3076 71.2832Z" fill="#FBECD6" />
            <path d="M51.7144 142.152C49.715 141.796 46.2612 140.947 44.0393 140.266C39.9302 139.007 31.2898 135.072 29.3224 133.563C28.2544 132.744 28.2497 132.693 28.9811 129.907C31.5491 120.126 38.6945 111.579 48.1407 106.99C53.8598 104.211 57.9537 103.281 64.5072 103.27C77.0704 103.248 88.1256 109.138 95.2036 119.624C97.1209 122.464 99.6775 128.317 99.7052 129.93C99.7167 130.601 98.7744 131.504 96.5857 132.919C89.4812 137.512 81.1524 140.782 72.9978 142.18C67.9096 143.053 56.69 143.038 51.7144 142.152Z" fill="#00B4B4" />
            <path d="M70.146 134.084L26.3079 97.0109C25.0214 95.923 23.0881 96.0767 21.9897 97.3543C20.8913 98.6319 21.0438 100.55 22.3303 101.637L66.1684 138.71C67.4549 139.798 69.3882 139.644 70.4866 138.367C71.585 137.089 71.4325 135.171 70.146 134.084Z" fill="#00B4B4" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M64.0879 54C60.4332 54 56.8143 54.6954 53.4378 56.0466C50.0613 57.3978 46.9933 59.3782 44.409 61.8749C41.8248 64.3715 39.7748 67.3355 38.3762 70.5975C36.9776 73.8595 36.2578 77.3558 36.2578 80.8866C36.2578 84.4174 36.9776 87.9136 38.3762 91.1756C39.7748 94.4377 41.8248 97.4016 44.409 99.8983C46.9933 102.395 50.0613 104.375 53.4378 105.727C56.8143 107.078 60.4332 107.773 64.0879 107.773C71.4688 107.773 78.5474 104.94 83.7665 99.8982C88.9856 94.856 91.9176 88.0173 91.9176 80.8866C91.9176 73.7558 88.9856 66.9172 83.7665 61.875C78.5474 56.8328 71.4688 54 64.0879 54ZM50.7076 71.2467H76.4218C80.6069 71.2467 83.9761 74.6162 83.9761 78.8013V83.8637C83.9761 88.0488 80.6069 91.418 76.4218 91.418H50.7076C46.5225 91.418 43.153 88.0488 43.153 83.8637V78.8013C43.153 74.6162 46.5225 71.2467 50.7076 71.2467Z" fill="#00B4B4" />
            <path d="M55.2858 85.4519C57.0099 85.4519 58.4076 83.7317 58.4076 81.6097C58.4076 79.4878 57.0099 77.7676 55.2858 77.7676C53.5617 77.7676 52.1641 79.4878 52.1641 81.6097C52.1641 83.7317 53.5617 85.4519 55.2858 85.4519Z" fill="#00B4B4" />
            <path d="M55.2858 85.4519C57.0099 85.4519 58.4076 83.7317 58.4076 81.6097C58.4076 79.4878 57.0099 77.7676 55.2858 77.7676C53.5617 77.7676 52.1641 79.4878 52.1641 81.6097C52.1641 83.7317 53.5617 85.4519 55.2858 85.4519Z" fill="#00B4B4" />
            <path d="M70.653 85.4519C72.3771 85.4519 73.7748 83.7317 73.7748 81.6097C73.7748 79.4878 72.3771 77.7676 70.653 77.7676C68.9289 77.7676 67.5312 79.4878 67.5312 81.6097C67.5312 83.7317 68.9289 85.4519 70.653 85.4519Z" fill="#00B4B4" />
        </svg>
    )
}

export const NoData = ({ width, mb, heading }) => {
    return (
        <Box mb={mb || 0}>
            <Box w={width || '200px'} h={width || '200px'} m={2} p={4} >
                <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 32 32" id="data-not-found">
                    <path fill="none" stroke="#00b4b4" stroke-linecap="round" stroke-linejoin="round" d="M23.5,27.5H6.5l-1-15.19a.76.76,0,0,1,.77-.81H10a1.11,1.11,0,0,1,.89.44l1.22,1.56H23.5v2"></path>
                    <path fill="none" stroke="#00b4b4" stroke-linecap="round" stroke-linejoin="round" d="M26.3,20.7l.84-3.2H9.25L6.5,27.5H23.41a1.42,1.42,0,0,0,1.37-1.06l.76-2.88"></path>
                    <path fill="none" stroke="#00b4b4" stroke-linecap="round" stroke-linejoin="round" d="M16.5,24.5h0a1.42,1.42,0,0,1,2,0h0"></path>
                    <line x1="13.5" x2="14.5" y1="21.5" y2="21.5" fill="none" stroke="#00b4b4" stroke-linecap="round" stroke-linejoin="round"></line>
                    <line x1="20.5" x2="21.5" y1="21.5" y2="21.5" fill="none" stroke="#00b4b4" stroke-linecap="round" stroke-linejoin="round"></line>
                    <path fill="none" stroke="#00b4b4" stroke-linecap="round" stroke-linejoin="round" d="M20.62,3.61C18.25,4,16.5,5.37,16.5,7a2.57,2.57,0,0,0,.7,1.7l-.7,2.8,2.86-1.43A8.12,8.12,0,0,0,22,10.5c3,0,5.5-1.57,5.5-3.5,0-1.6-1.69-2.95-4-3.37"></path>
                    <line x1="21.25" x2="22.75" y1="6.25" y2="7.75" fill="none" stroke="#00b4b4" stroke-linecap="round" stroke-linejoin="round"></line>
                    <line x1="22.75" x2="21.25" y1="6.25" y2="7.75" fill="none" stroke="#00b4b4" stroke-linecap="round" stroke-linejoin="round"></line>
                </svg>
            </Box>
            <Text px={2} color={'white.500'} bg={'moderntheme.500'} rounded={'full'} textAlign={'center'}>
                {heading ? heading : 'No Data Available'}
            </Text>
        </Box>
    )
}


