import { Flex } from '@chakra-ui/layout';
// import { Switch } from '@chakra-ui/switch';
import { Tag } from '@chakra-ui/tag';
import React, { useContext } from 'react'
import { NewThemeContext } from '../../lib/Providers/ThemeSwitch';
import { Switch } from 'antd';
import SlideRightToLeft from '../Common/Transition/SlideRightToLeft'
export const ThemeSwitchButton = () => {

    const { theme, setTheme } = useContext(NewThemeContext);
    const theme_value = theme ? 'modern' : 'classic';
    return (
        <SlideRightToLeft>
            <Flex gap='2' alignItems='center' bg='messenger'>
                <Switch
                    title={theme_value}
                    style={{ backgroundColor: theme ? '#00B4B4' : '#4168F5', fontWeight: '600', }}
                    onChange={e => {
                        // console.log('stored_theme',theme_value);
                        localStorage.setItem('theme', e)
                        setTheme(e);
                    }}
                    checked={theme}
                    checkedChildren="Modern"
                    unCheckedChildren="Classic"
                />
            </Flex>
        </SlideRightToLeft>
    )
}