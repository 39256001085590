import { IconButton, Tooltip } from '@chakra-ui/react'
import React, { useContext, useState } from 'react'
import { BsFullscreen, BsFullscreenExit } from 'react-icons/bs'
import { NewThemeContext } from '../../lib/Providers/ThemeSwitch';

export const FullScreenIconButton = () => {
    const { theme } = useContext(NewThemeContext);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const toggleFullScreen = () => {
        if (!isFullScreen) {
            openFullscreen();
        } else {
            closeFullscreen();
        }
    };

    const openFullscreen = () => {
        const elem = document?.documentElement;
        if (elem?.requestFullscreen) {
            elem?.requestFullscreen();
        } else if (elem?.mozRequestFullScreen) { // Firefox
            elem?.mozRequestFullScreen();
        } else if (elem?.webkitRequestFullscreen) { // Chrome, Safari and Opera
            elem?.webkitRequestFullscreen();
        } else if (elem?.msRequestFullscreen) { // IE/Edge
            elem?.msRequestFullscreen();
        }

        setIsFullScreen(true);
    };

    const closeFullscreen = () => {
        if (document?.exitFullscreen) {
            document?.exitFullscreen();
        } else if (document?.mozCancelFullScreen) { // Firefox
            document?.mozCancelFullScreen();
        } else if (document?.webkitExitFullscreen) { // Chrome, Safari and Opera
            document?.webkitExitFullscreen();
        } else if (document?.msExitFullscreen) { // IE/Edge
            document?.msExitFullscreen();
        }

        setIsFullScreen(false);
    };

    return (
        <>
            <IconButton
                onClick={toggleFullScreen}
                _hover={{ bg: 'gray.100' }}
                rounded="full"
                bg='none'
                aria-label='Search database'
                icon={isFullScreen ?
                    <>
                        <Tooltip fontSize='sm' label="Exit Fullscreen">
                            <IconButton bg={!theme ? '' : 'none'} color={!theme ? 'primary.500' : '#00b4b4'} aria-label='Search database' icon={<BsFullscreenExit />} />
                        </Tooltip>
                    </>
                    :
                    <>
                        <Tooltip fontSize='sm' label="Fullscreen">
                            <IconButton bg={!theme ? '' : 'none'} color={!theme ? 'primary.500' : '#00b4b4'} aria-label='Search database' icon={<BsFullscreen />} />
                        </Tooltip>
                    </>
                }
            />
        </>
    )
}
