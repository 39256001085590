import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Config from '../lib/Config/Config';

const useAppVersion = () => {
    const [appVersion, setAppVersion] = useState('0.0.0');
    const GetAPIVersion = async () => {
        try {
            const response = await axios.get(Config.Get_App_Version);
            if (response.status === 200) {
                // console.log("Get_App_Version", response?.data?.data);
                setAppVersion(response?.data?.data);
                // return response?.data?.data;
            }
        } catch (error) {
            console.log('logout');
        }
    }

    useEffect(() => {
        GetAPIVersion();
    }, [])

    return { appVersion }
}

export default useAppVersion