import { Box, BreadcrumbItem, Breadcrumb, BreadcrumbLink, Flex, FormControl, FormErrorMessage, FormLabel, HStack, Input, VStack, Heading, Button } from '@chakra-ui/react';
// import { Breadcrumb } from 'antd';
import React, { useContext, useState } from 'react'
import { NewThemeContext } from '../../lib/Providers/ThemeSwitch';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import Config from '../../lib/Config/Config';
import axios from 'axios';
import { CustomToast } from '../Common/Toast';
import * as Yup from 'yup';

const StatusCode = () => {
    const { theme } = useContext(NewThemeContext);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { addToast } = CustomToast();
    const [statusCode, setStatusCode] = useState({});



    const PostStatusCode = async (values) => {
        // if (!values.status_code) {
        //     // If the input field is empty, navigate to the '/add-action' route
        //     navigate('/add-action');
        //     return; // Exit early, no need to make API call
        // }
        setLoading(true);
        try {
            const response = await axios.post(`${Config.Post_Status_Code}`, values)
            console.log('responseData', response?.data?.data);
            if (response?.status === 200) {
                const data = response?.data?.data;
                setStatusCode(data);
                addToast({
                    message: 'Status Code added successfully.',
                    type: 'success',
                });
                navigate('/add-action')
            }
        } catch (error) {
            addToast({
                message:
                    error?.response?.data?.meta?.msg ||
                    error?.message ||
                    'Server Error !!!',
                type: 'info',
            });
        } finally {
            setLoading(false);
        }
    }


    const initialValues = {
        status_code: '',
    }

    const { handleSubmit, handleChange, handleBlur, setFieldValue, values, errors, touched, setFieldError, resetForm, } = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: Yup.object({
          status_code: Yup.string().required('Status code is required'),
        }),
        onSubmit: values => {
            //   let newValues = values;
            //   if (!isNextFollowUpRequired) { newValues = { ...values, next_follow_up_date: '' }; }
            PostStatusCode(values);
        },
    });

    return (
        <>
            <Box>
                <Breadcrumb
                    ml={2.5}
                    mt={4}
                    mb={4}
                    fontSize={!theme ? 'smaller' : 'lg'}
                    fontWeight={!theme ? 'normal' : 'semibold'}
                    spacing="8px"
                    separator={<MdKeyboardArrowRight color="gray.500" />}
                >
                    <BreadcrumbItem>
                        <BreadcrumbLink
                            color="brand.500"
                            onClick={() => {
                                navigate('/projects');
                            }}
                        >
                            Project List
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                    {/* <BreadcrumbItem>
                        <BreadcrumbLink
                            color="brand.500"
                            onClick={() => {
                                navigate('/task-list');
                            }}
                        >
                            Project Overview
                        </BreadcrumbLink>
                    </BreadcrumbItem> */}
                    <BreadcrumbItem isCurrentPage>
                        <BreadcrumbLink
                            color={!theme ? 'primary.500' : 'gray.500'}
                            onClick={() => {
                                navigate('#');
                            }}
                        >
                            Add Status Code
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>
                <Box border="solid 1px" borderColor="gray.200" rounded="lg" bg="white.500">
                    <Heading p={3} borderBottom="solid 1px" borderColor="gray.200" fontSize="sm" color="brand.500">Status Code</Heading>
                    <form onSubmit={handleSubmit}>
                        <VStack p={8} spacing={6} >
                            <HStack width="70%" gap={8}>
                                <FormControl isInvalid={errors.status_code && touched.status_code} >
                                    <FormLabel>Status Code</FormLabel>
                                    <Flex alignItems='center' gap='2' >
                                        <Input
                                            placeholder="Enter status code"
                                            name="status_code"
                                            w='full'
                                            onChange={e => {
                                                setFieldValue('status_code', e?.target?.value)
                                            }}
                                            onBlur={handleBlur}
                                            value={values.status_code}
                                            fontSize='sm' fontWeight={500}
                                        />

                                    </Flex>
                                    <FormErrorMessage>{errors.status_code}</FormErrorMessage>
                                </FormControl>

                            </HStack>
                        </VStack>
                        <Flex p={3} borderTop="solid 1px" borderColor="gray.200" fontSize="sm" color="brand.500" justifyContent="end">
                            <HStack>
                                <Button
                                    isLoading={loading}
                                    loadingText="Submitting..."
                                    type="submit"
                                    // onClick={goToNextStep}
                                    _hover={{ bg: !theme ? 'primary.400' : 'moderntheme.500' }}
                                    bg={!theme ? "primary.500" : 'moderntheme.500'}
                                    color="white.500"
                                    py={2}
                                    height="auto"
                                    fontSize={12}
                                // onClick={() => navigate('/add-action')}
                                >
                                    Save & Next
                                </Button>
                                {/* <Button isDisabled={loading} py={2} height="auto" fontSize={12} onClick={() => { resetForm(); }}>Cancel</Button> */}
                            </HStack>
                        </Flex>

                    </form>
                </Box>
            </Box>

        </>
    )
}

export default StatusCode;
