import {
    Box,
    Button,
    Container,
    Flex,
    HStack,
    IconButton,
    Image,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text
} from '@chakra-ui/react';
import React, { useContext } from 'react';
// import { SearchIcon } from "@chakra-ui/icons";
import { HiOutlineMenuAlt2, HiOutlineTicket } from 'react-icons/hi';
import { FullScreenIconButton } from '../../Molecules/IconButton/FullScreenIconButton';
import full_logo from '../../assets/image/Logo/opsnex_full.svg';
import { NewThemeContext } from '../../lib/Providers/ThemeSwitch';
import { CreateMenu } from '../Navbar/CreateMenu';
import GlobalMenuSearchPopup from '../popups/GlobalMenuSearchPopup';
import ProfileNavbarPopup from '../popups/ProfileNavbarPopup';
import ModernNavbar from './ModernNavbar';
import { ThemeSwitchButton } from '../Buttons/ThemeSwitchButton';
import { ModernCreatemenu } from '../Navbar/ModernCreatemenu';
import { useNavigate } from 'react-router-dom';
import { Verifycontext } from '../../lib/Providers/LoginVeification';
import { SearchprojectContext } from '../../lib/Providers/Searchproject';
import { ChevronDownIcon } from '@chakra-ui/icons';
const Navbar = ({ SetIsOpen, isOpened }) => {
    const { theme } = useContext(NewThemeContext);
    const { projectName, projectList, ProjectId, setProjectName, setProjectList, setProjectId } = useContext(SearchprojectContext)
    console.log("prokejdyd", projectName, projectList, ProjectId);
    const pathname = window.location.pathname
    console.log(pathname, "path");
    const {
        manager_access,
        admin_access,
        employee_access,
        superviser_access,
    } = useContext(Verifycontext);
    const navigate = useNavigate();
    const toggle = () => SetIsOpen(!isOpened);
    return (
        <Box
            top={'0'}
            zIndex={'999 !important'}
            position={'sticky !important'}
            borderBottom={'1px solid'}
            borderColor='gray.200'
            padding='0px'
            py={1}
            height={!theme ? '60px' : 'auto'}
            bg='white.500'
        >
            <Container maxW={!theme ? 'full' : '9xl'}>
                <Flex justifyContent={'space-between'} alignItems={'center'}>
                    <Flex alignItems="center">
                        <IconButton
                            // visibility={!theme ? 'visible' : 'hidden'}
                            display={!theme ? 'block' : 'none'}
                            _hover={{ bg: 'transparent' }}
                            bg='transparent'
                            width='60px'
                            height='50px'
                            borderRadius='0'
                            aria-label='Search database'
                            onClick={toggle}
                            icon={<HiOutlineMenuAlt2 color="brand.500" fontSize='25px' />}
                        />
                        <Image
                            display={!theme ? 'none' : 'block'}
                            // style={{ display: isOpen ? "none" : "block" }}
                            src={full_logo}
                            objectFit="contain"
                            blendMode='multiply'
                        />
                        <Box ml={!theme ? 0 : 4}>
                            <GlobalMenuSearchPopup />
                        </Box>
                        {theme && employee_access && (pathname === '/dashboard' || pathname === '/') && <Box>
                            {projectList?.length > 0 &&
                                (<Menu>
                                    <MenuButton
                                        className='project_select'
                                        // overflowY={'scroll'}
                                        // overflow={'hidden'}
                                        textAlign='start'
                                        minWidth='150px'
                                        maxWidth='150px'
                                        width='110px'
                                        color='gray.500'
                                        bg='transparent'
                                        fontWeight='medium'
                                        fontSize={13}
                                        border='solid 1px'
                                        rounded='full'
                                        borderColor='gray.300'
                                        textTransform={'capitalize'} as={Button} rightIcon={<ChevronDownIcon fontSize={24} />}>
                                        {projectName}
                                    </MenuButton>
                                    <MenuList
                                        height={'295px !important'}
                                        overflow={'hidden'}
                                        overflowY={'scroll'}
                                    >
                                        {
                                            projectList?.map((data, index) => {
                                                return (
                                                    <MenuItem
                                                        key={index}
                                                        bg={'moderntheme.500'}
                                                        color={'white.500'}
                                                        textTransform={'capitalize'}
                                                        transition='all 0.3s'
                                                        _hover={{
                                                            bg: '#fff',
                                                            color: '#00b4b4',
                                                            transition: 'all 0.3s'
                                                        }}
                                                        onClick={() => {
                                                            setProjectId(data?.id);
                                                            setProjectName(data?.project_name);
                                                        }}>
                                                        {data?.project_name}
                                                    </MenuItem>
                                                )
                                            })
                                        }
                                    </MenuList>
                                </Menu>)
                            }
                            {projectList?.length === 0 &&
                                (
                                    // <Text className='object-rop'>
                                    //     No Projects Assigned
                                    // </Text>
                                    <Box className="object">
                                        <Box className="object-rope"></Box>
                                        <Box className="object-shape">
                                            No Project Assigned
                                        </Box>
                                    </Box>
                                )}
                        </Box>}
                    </Flex>
                    <Box><ModernNavbar /></Box>
                    <HStack justifyContent={'end'} alignItems={'center'} >
                        <HStack gap={2}>
                            <ThemeSwitchButton />
                            {!theme ? <CreateMenu /> : <ModernCreatemenu />}
                            <FullScreenIconButton />
                            {
                                theme && employee_access &&
                                <Button
                                    leftIcon={<HiOutlineTicket color='white.500' fontSize={'18px'} />}
                                    color={'white.500'}
                                    bg='moderntheme.500'
                                    fontWeight={500}
                                    fontSize={14}
                                    onClick={() => {
                                        navigate('raise-ticket')
                                    }}
                                    _hover={{ bg: 'moderntheme.500' }}
                                >
                                    Raise Ticket
                                </Button>
                            }
                            {
                                theme && (manager_access || superviser_access) &&
                                <Button
                                    leftIcon={<HiOutlineTicket color='white.500' fontSize={'18px'} />}
                                    color={'white.500'}
                                    bg='moderntheme.500'
                                    onClick={() => {
                                        navigate('raise-ticket')
                                    }}
                                    _hover={{ bg: 'moderntheme.500' }}
                                >
                                    Ticket List
                                </Button>
                            }
                        </HStack>
                        <ProfileNavbarPopup />
                    </HStack>
                </Flex>
            </Container>
        </Box>
    );
}
export default React.memo(Navbar)