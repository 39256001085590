// import React, { useState } from 'react';
import '../src/styles/global.css'
import '../src/styles/App.css'
import '../src/styles/spinner.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { LoginVeification } from './lib/Providers/LoginVeification';
import { Center, Spinner } from '@chakra-ui/react';
import { ThemeSwitch } from './lib/Providers/ThemeSwitch';
import LoginModern from './pages/Login/index2';
import loadable from '@loadable/component'
import React from 'react'
// import Dashboard from './pages/Dashboard';

import Navbar from './components/Global/Navbar';
import ModernNavbar from './components/Global/ModernNavbar';
import LoadingTopBar from './components/LoadingTopBar/LoadingTopBar';
import { SearchprojectProvider } from './lib/Providers/Searchproject';
import StatusCode from './components/Projects/StatusCode';
import ActionCode from './components/Projects/ActionCode';


// const Login = loadable(() => import('./pages/Login'));
const Dashboard = loadable(() => import('./pages/Dashboard'), {
  fallback: <Center height='100vh'
    transition='transform 0.3s ease-in-out'>
    <div className="spinner"></div>
  </Center>
});
const EmployeeDetailView = loadable(() => import('./components/dashboard/DetailView/EmployeeDetailView'), {
  fallback: <Center height='100vh'
    transition='transform 0.3s ease-in-out'>
    <div className="spinner"></div>
  </Center>
});
const SupervisorDetailView = loadable(() => import('./components/dashboard/DetailView/SupervisorDetailView'), {
  fallback: <Center height='100vh'
    transition='transform 0.3s ease-in-out'>
    <div className="spinner"></div>
  </Center>
});
const Sidebar = loadable(() => import('./components/Global/Sidebar'), {
  fallback: <Center height='100vh'
    transition='transform 0.3s ease-in-out'>
    <div className="spinner"></div>
  </Center>
});
const ProjectList = loadable(() => import('./pages/Projects/ProjectList'), {
  fallback: <Center height='100vh'
    transition='transform 0.3s ease-in-out'>
    <div className="spinner"></div>
  </Center>
});
const Addproject = loadable(() => import('./pages/Projects/Addproject'), {
  fallback: <Center height='100vh'
    transition='transform 0.3s ease-in-out'>
    <div className="spinner"></div>
  </Center>
});
const TaskDataList = loadable(() => import('./pages/Projects/ProjectTask'), {
  fallback: <Center height='100vh'
    transition='transform 0.3s ease-in-out'>
    <div className="spinner"></div>
  </Center>
});
const RoleList = loadable(() => import('./pages/Role'), {
  fallback: <Center height='100vh'
    transition='transform 0.3s ease-in-out'>
    <div className="spinner"></div>
  </Center>
});
const AddRole = loadable(() => import('./pages/Role/AddRole'), {
  fallback: <Center height='100vh'
    transition='transform 0.3s ease-in-out'>
    <div className="spinner"></div>
  </Center>
});
const MemberList = loadable(() => import('./pages/Members'), {
  fallback: <Center height='100vh'
    transition='transform 0.3s ease-in-out'>
    <div className="spinner"></div>
  </Center>
});
const AddMember = loadable(() => import('./pages/Members/AddMember'), {
  fallback: <Center height='100vh'
    transition='transform 0.3s ease-in-out'>
    <div className="spinner"></div>
  </Center>
});
const Reporting = loadable(() => import('./pages/Reporting'), {
  fallback: <Center height='100vh'
    transition='transform 0.3s ease-in-out'>
    <div className="spinner"></div>
  </Center>
});
const DepartmentList = loadable(() => import('./pages/Department/DepartmentList'), {
  fallback: <Center height='100vh'
    transition='transform 0.3s ease-in-out'>
    <div className="spinner"></div>
  </Center>
});
const ProtfolioList = loadable(() => import('./pages/Portfolio/PortfolioList'), {
  fallback: <Center height='100vh'
    transition='transform 0.3s ease-in-out'>
    <div className="spinner"></div>
  </Center>
});
const AddPortfolio = loadable(() => import('./pages/Portfolio/AddPortfolio'), {
  fallback: <Center height='100vh'
    transition='transform 0.3s ease-in-out'>
    <div className="spinner"></div>
  </Center>
});
const AddDepartment = loadable(() => import('./pages/Department/AddDepartment'), {
  fallback: <Center height='100vh'
    transition='transform 0.3s ease-in-out'>
    <div className="spinner"></div>
  </Center>
});
const EditMember = loadable(() => import('./pages/Members/EditMember'), {
  fallback: <Center height='100vh'
    transition='transform 0.3s ease-in-out'>
    <div className="spinner"></div>
  </Center>
});
const AddProfile = loadable(() => import('./components/Global/AddProfile'), {
  fallback: <Center height='100vh'
    transition='transform 0.3s ease-in-out'>
    <div className="spinner"></div>
  </Center>
});
const MyTask = loadable(() => import('./components/MyTask/MyTask'), {
  fallback: <Center height='100vh'
    transition='transform 0.3s ease-in-out'>
    <div className="spinner"></div>
  </Center>
});
const EditTask = loadable(() => import('./components/MyTask/EditTask'), {
  fallback: <Center height='100vh'
    transition='transform 0.3s ease-in-out'>
    <div className="spinner"></div>
  </Center>
});
const ResetPassword = loadable(() => import('./pages/Reset/ResetPassword'), {
  fallback: <Center height='100vh'
    transition='transform 0.3s ease-in-out'>
    <div className="spinner"></div>
  </Center>
});
const EditProject = loadable(() => import('./pages/Projects/EditProject'), {
  fallback: <Center height='100vh'
    transition='transform 0.3s ease-in-out'>
    <div className="spinner"></div>
  </Center>
});
const RaiseTicket = loadable(() => import('./components/Global/RaiseTicket'), {
  fallback: <Center height='100vh'
    transition='transform 0.3s ease-in-out'>
    <div className="spinner"></div>
  </Center>
});
const AddTicket = loadable(() => import('./components/Global/AddTicket'), {
  fallback: <Center height='100vh'
    transition='transform 0.3s ease-in-out'>
    <div className="spinner"></div>
  </Center>
});
const TicketDetails = loadable(() => import('./components/Global/TicketDetails'), {
  fallback: <Center height='100vh'
    transition='transform 0.3s ease-in-out'>
    <div className="spinner"></div>
  </Center>
});
const AddStatusCode = loadable(() => import('./components/Projects/StatusCode'), {
  fallback: <Center height='100vh'
    transition='transform 0.3s ease-in-out'>
    <div className="spinner"></div>
  </Center>
});
const AddActionCode = loadable(() => import('./components/Projects/ActionCode'), {
  fallback: <Center height='100vh'
    transition='transform 0.3s ease-in-out'>
    <div className="spinner"></div>
  </Center>
});
const PageNotFound = loadable(() => import('./Molecules/NoData/PageNotFound'), {
  fallback: <Center height='100vh'
    transition='transform 0.3s ease-in-out'>
    <div className="spinner"></div>
  </Center>
});



function App() {

  const token = sessionStorage.getItem('token');

  return (
    <BrowserRouter>
      <LoadingTopBar />
      <ThemeSwitch>
        <LoginVeification>
          <SearchprojectProvider>
            <Routes>
              <Route path="/login" element={<LoginModern />} />
              <Route path="/reset/password" element={<ResetPassword />} />
              <Route element={<Sidebar />}>
                <Route path='/' element={<Dashboard />} />
                <Route path='/dashboard' element={<Dashboard />} />
                <Route path='/employee/detail/view' element={<EmployeeDetailView />} />
                <Route path='/supervisor/detail/view' element={<SupervisorDetailView />} />
                <Route path='/projects' element={<ProjectList />} />
                <Route path='/add-project' element={<Addproject />} />
                <Route path='/task-list' element={<TaskDataList />} />
                <Route path='/role' element={<RoleList />} />
                <Route path='/add-role' element={<AddRole />} />
                <Route path='/employee-list' element={<MemberList />} />
                <Route path='/add-employee' element={<AddMember />} /> 
                <Route path='/edit-employee' element={<EditMember />} />
                {/* <Route path='/reporting' element={<Reporting />} /> */}
                <Route path='/client' element={<ProtfolioList />} />
                <Route path='/add-client' element={<AddPortfolio />} />
                <Route path='/department' element={<DepartmentList />} />
                <Route path='/add-department' element={<AddDepartment />} />
                <Route path='/profile' element={<AddProfile />} />
                <Route path='/my-task' element={<MyTask />} />
                <Route path='/edit-task' element={<EditTask />} />
                <Route path='/edit-project' element={<EditProject />} />
                <Route path='/raise-ticket' element={<RaiseTicket />} />
                <Route path='/add-ticket' element={<AddTicket />} />
                <Route path='/ticket-details' element={<TicketDetails />} />
                <Route path='/add-status' element={<AddStatusCode />} />
                <Route path='/add-action' element={<AddActionCode />} />
                <Route path='*' element={<PageNotFound />} />
              </Route>
            </Routes>
          </SearchprojectProvider>
        </LoginVeification>
      </ThemeSwitch>
    </BrowserRouter >
  );
}

export default React.memo(App);
