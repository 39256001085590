import React, { createContext, useEffect, useState } from 'react';
import { ConfigProvider } from 'antd';

const NewThemeContext = createContext();

function ThemeSwitch({ children }) {

    // classic : false
    // modern : true
    // theme blue #4168F5
    // theme green #00B4B4

    const stored_theme = localStorage.getItem('theme');
    const [theme, setTheme] = useState(false); //classic by defalut
    const [themeColor, setThemeColor] = useState('#4168F5');

    useEffect(() => {
        const color = theme ? '#00B4B4' : '#4168F5';
        if (stored_theme === 'true') { setTheme(true); localStorage.setItem('theme', true); }
        else if (stored_theme === 'false') { setTheme(false); localStorage.setItem('theme', false); }
        else { setTheme(false); localStorage.setItem('theme', false); }
        setThemeColor(color);
    }, [theme]);
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: themeColor,
                },
            }}
        >
            <NewThemeContext.Provider value={{ theme: theme, setTheme: setTheme }}>
                {children}
            </NewThemeContext.Provider>
        </ConfigProvider>
    )
}

export { NewThemeContext, ThemeSwitch };

