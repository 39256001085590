import axios from 'axios';
import Config from '../lib/Config/Config';
import { useEffect, useState } from 'react';
const useStatusCode = () => {
    const [_statusCode, setStatusCode] = useState([]);
    const GetStatusCode = async () => {
        try {
            const response = await axios.get(`${Config.Get_Claim_Status_Codes}`);
            if (response?.status === 200) {
                setStatusCode(response?.data?.data);
            }
        }
        catch (error) {
            console.error('GetStatusCode', error?.response?.data?.data?.msg || error?.message || 'Server Error !!!');
        }
    }
    useEffect(() => {
        GetStatusCode();
    }, []);
    return { _statusCode }
}
export default useStatusCode