import { Avatar, Box, Button, HStack, Menu, MenuButton, MenuItem, MenuList, Text, useDisclosure } from '@chakra-ui/react';
import React, { useContext } from 'react'
import { BiLogOut } from 'react-icons/bi';
import { FaRegUser } from 'react-icons/fa';
import { Verifycontext } from '../../lib/Providers/LoginVeification';
import { Link, useNavigate } from 'react-router-dom';
import { Defaults } from '../../utils/Defaults';
import DeletePopup from './DeletePopup';
import { NewThemeContext } from '../../lib/Providers/ThemeSwitch';
import avtar_pro from '../../../src/assets/image/avtar_pro.png'


const ProfileNavbarPopup = () => {
    const { theme } = useContext(NewThemeContext);
    const { logout_api, clearSessionStorage } = Defaults();
    const navigate = useNavigate();
    const { Medata, Module, admin_access, manager_access, superviser_access } = useContext(Verifycontext);
    const fullName = Medata?.firstName ? `${Medata?.firstName} ${Medata?.lastName}` : '---';
    const { isOpen, onOpen, onClose } = useDisclosure();

    const content = {
        heading: 'Logout',
        message: 'Are you Sure? You want to Logout.',
        submitButton: 'OK',
        color: 'red'
    }

    const handleLogout = () => {
        logout_api();
        clearSessionStorage();
    }

    return (
        <>
            <DeletePopup
                content={content}
                isOpen={isOpen}
                onClose={onClose}
                cancel="Cancel"
                handleStatus={handleLogout}
            />
            <Menu>
                <MenuButton
                    bg="transprant"
                    _hover={{ bg: "transprant" }}
                    _active={{ bg: "transprant" }}
                    p={!theme ? '' : '0'}
                    as={Button}>
                    <HStack>
                        <Box display={!theme ? 'block' : 'none'} className={!theme ? 'ni_hed_click' : 'ni_hed_clickk'}>
                            <h3>{fullName}</h3>
                            <Text fontSize='sm'>{Medata?.email || '---'}</Text>
                        </Box>
                        <Box
                            borderRadius='50%'
                            backgroundColor={!theme ? '#007b7b14' : 'transparent'}
                            width={!theme ? '50px' : 'auto'}
                            height='50px'
                        >
                            {!theme ? <Avatar bg='#fff' height='40px'
                                marginTop='5px'
                                width='40px' icon={<FaRegUser color={!theme ? '#4168f5' : '#00b4b4'} />} />
                                :
                                <Avatar bg='#fff' height='40px'
                                    src={avtar_pro}
                                    marginTop='5px'
                                    width='40px' />
                            }

                        </Box>
                    </HStack>
                </MenuButton>
                <MenuList className='menu_list'>
                    <Box padding={'10px'} pl={'19px'}>
                        <HStack>
                            <Box
                                borderRadius='50%'
                                backgroundColor='#007b7b14'
                                width='50px'
                                height='50px'
                            >
                                {!theme ?
                                    <Avatar bg='#EEEEEE' height='50px'
                                        width='50px' icon={<FaRegUser color='#0D99FF' />} />
                                    :
                                    <Avatar bg='#EEEEEE' height='50px'
                                        width='50px' src={avtar_pro} />
                                }

                            </Box>
                            <Box className='ni_hed_click'>
                                <Text fontWeight={400} textTransform='capitalize' as='h3' textAlign='start !important' color={!theme ? 'inherit' : '#828393!important'}>{fullName}</Text>
                                <Text fontWeight={400} fontSize='sm' color={!theme ? 'inherit' : '#bbbcc4!important'}>{Medata?.email || '---'}</Text>
                            </Box>

                        </HStack>
                    </Box>

                    <MenuItem className='menu_item'
                    onClick={() => navigate('/profile')}
                    > <HStack>
                        <FaRegUser color={!theme ? '#0D99FF' : '#00000094'} />
                        <Text color='#00000094 !important'>Profile</Text>
                    </HStack></MenuItem>
                <MenuItem
                    className='menu_item'
                    // onClick={onOpen}
                    onClick={() => {
                        // logout_api();
                        // handleLogout();
                        onOpen();
                    }}
                >
                        <HStack>
                            <BiLogOut transform= 'scaleX(-1)' fontSize='20px' color='red'/>
                            <Text color='red !important'>Logout</Text>
                        </HStack>
                </MenuItem>

            </MenuList>
        </Menu >
        </>
    )
}

export default React.memo(ProfileNavbarPopup);
