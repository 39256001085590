import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputRightElement,
    Stack,
    Button,
    Heading,
    Text,
    useColorModeValue,
    Link,
    Img,
    FormErrorMessage,
    Kbd,
} from '@chakra-ui/react'
import { BsEye, BsEyeSlash } from 'react-icons/bs'
import logo from '../../assets/image/Logo/opsnex_full.svg'
import Lottie from "lottie-react";
import loginbag from '../../assets/animation/login-bag.json'
import useLogin from '../../hooks/useLogin';
import React from 'react';

const LoginModern = () => {
    const { errors, handleSubmit, loading, touched, values, handleBlur, handleChange, appVersion ,showPassword, setShowPassword} = useLogin();
    return (
        <Flex
            minH={'100vh'}
            align={'center'}
            justify={'center'}
            bg={useColorModeValue('#D7F1F0', 'gray.800')}>
            <Stack spacing={8} mx={'auto'} maxW={'4xl'} py={12} px={6}>
                <Box
                    position='relative'
                    rounded={'lg'}
                    bg={useColorModeValue('white', 'gray.700')}
                    boxShadow={'lg'}
                >
                    <Flex>
                        <Box
                            roundedBottomLeft={'lg'}
                            roundedTopLeft={'lg'}
                            width='50%'
                            bg='#00b4b4'
                            p={8}>
                            <Heading
                                fontWeight='bold'
                                mb={1}
                                color='white.500'
                                fontSize='3xl'
                                textAlign='center'>
                                Welcome Back
                            </Heading>
                            <Text
                                fontWeight='medium'
                                color='white.500'
                                fontSize={14}
                                textAlign='center'
                            >
                                Welcome to our secure portal, where access meets simplicity.
                            </Text>
                            <Lottie style={{ width: 'auto', margin: 'auto' }} animationData={loginbag} loop={true} />
                        </Box>
                        <Stack pos='relative' width='50%' p={12} spacing={6}>
                            <Img top='15px' left='15px' pos='absolute' src={logo} alt='ops' />
                            <form onSubmit={handleSubmit}>
                                <Box my={8}>
                                    <Heading textAlign='center' color='#00b4b4' fontWeight='bold' fontSize={'2xl'} mb={1}>Log In</Heading>
                                    <Text fontWeight='medium' textAlign='center' fontSize={12}>Enter your valid credential for logging in</Text>
                                </Box>
                                <FormControl mb='4' id="email" isRequired isDisabled={loading} isInvalid={errors?.email && touched?.email}>
                                    <FormLabel fontSize={'sm'}>Enter your email</FormLabel>
                                    <Input
                                        placeholder='Email'
                                        required
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        type="email"
                                        onBlur={handleBlur}
                                    />
                                    <FormErrorMessage>{errors?.email}</FormErrorMessage>
                                </FormControl>
                                <FormControl mb='4' id="password" isRequired isDisabled={loading} isInvalid={errors?.password && touched?.password}>
                                    <FormLabel fontSize={'sm'}>Enter your password</FormLabel>
                                    <InputGroup>
                                        <Input
                                            placeholder='Password'
                                            type={showPassword ? 'text' : 'password'}
                                            required
                                            fontSize={14}
                                            name="password"
                                            value={values.password}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <InputRightElement h={'full'}>
                                            <Button
                                                minW='auto'
                                                variant={'ghost'}
                                                onClick={() => setShowPassword((showPassword) => !showPassword)}>
                                                {showPassword ? <BsEye /> : <BsEyeSlash />}
                                            </Button>
                                        </InputRightElement>
                                    </InputGroup>
                                    <FormErrorMessage>{errors?.password}</FormErrorMessage>
                                </FormControl>
                                <Stack spacing={10} pt={2}>
                                    <Button
                                        isLoading={loading}
                                        type='submit'
                                        loadingText={"Submitting"}
                                        size="md"
                                        bg={'#00b4b4'}
                                        color={'white'}
                                        _hover={{
                                            bg: '#00b4b4',
                                        }}>
                                        Log In
                                    </Button>
                                </Stack>
                                <Stack pt={6}>
                                    <Text fontSize={'sm'} align={'center'}>
                                        Created By <Link href='https://gosource.in/' color='#00b4b4' target='_blank'>GoSource</Link>
                                    </Text>
                                </Stack>
                            </form>
                        </Stack>
                    </Flex>
                    <Kbd position='absolute' right='0' my='1' fontWeight='semibold' bg='transparent' border='0px solid transparent'>version {appVersion?.version || '0.0.0'}</Kbd>
                </Box>
            </Stack>
        </Flex>
    )
}
export default React.memo(LoginModern)