import { extendTheme } from "@chakra-ui/react";
import TableStyles from "./components/Common/styles";
export const myNewTheme = extendTheme({
    colors: {
        brand: {
            100: "#d2d4d8",
            200: "#a5a9b1",
            300: "#787f89",
            400: "#4b5462",
            500: "#1e293b",
            600: "#18212f",
            700: "#121923",
            800: "#0c1018",
            900: "#06080c"
        },
        white: {
            100: "#ffffff",
            200: "#ffffff",
            300: "#ffffff",
            400: "#ffffff",
            500: "#ffffff",
            600: "#cccccc",
            700: "#999999",
            800: "#666666",
            900: "#333333"
        },
        gray: {
            100: "#EDF2F7",
            200: "#E2E8F0",
            300: "#CBD5E0",
            400: "#A0AEC0",
            500: "#718096",
            600: "#4A5568",
            700: "#2D3748",
            800: "#1A202C",
            900: "#171923"
        },
        green: {
            100: "#C6F6D5",
            200: "#9AE6B4",
            300: "#68D391",
            400: "#48BB78",
            500: "#38A169",
            600: "#2F855A",
            700: "#276749",
            800: "#22543D",
            900: "#1C4532"
        },
        primary: {
            100: "#d9e1fd",
            200: "#b3c3fb",
            300: "#8da4f9",
            400: "#6786f7",
            500: "#4168f5",
            600: "#3453c4",
            700: "#273e93",
            800: "#1a2a62",
            900: "#0d1531"
        },
        moderntheme: {
          100: "#ccf0f0",
          200: "#99e1e1",
          300: "#66d2d2",
          400: "#33c3c3",
          500: "#00b4b4",
          600: "#009090",
          700: "#006c6c",
          800: "#004848",
          900: "#002424"
},
    },
    TableStyles,

});