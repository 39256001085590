import React, { useContext, useState } from 'react'
import { NewThemeContext } from '../../lib/Providers/ThemeSwitch';
import { useNavigate, useLocation } from 'react-router-dom';
import { CustomToast } from '../Common/Toast';
import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Button, Flex, FormControl, FormErrorMessage, FormLabel, HStack, Heading, Input, Select, VStack } from '@chakra-ui/react';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { useFormik } from 'formik';
import useStatusCode from '../../hooks/useStatusCode';
import axios from 'axios';
import Config from '../../lib/Config/Config';
import * as Yup from 'yup';

const ActionCode = () => {
    const { theme } = useContext(NewThemeContext);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { addToast } = CustomToast();
    const [actionCode, setActionCode] = useState([]);
    const { _statusCode } = useStatusCode();

    // console.log('statuscode', _statusCode);

    const PostActionCode = async (values) => {
        setLoading(true);
        try {
            const response = await axios.post(`${Config.Post_Action_Code}`, values)
            console.log('responseData', response?.data?.data);
            if (response?.status === 200) {
                const data = response?.data?.data;
                setActionCode(data);
                addToast({
                    message: 'Action Code added successfully.',
                    type: 'success',
                });
                navigate('/projects')
            }
        } catch (error) {
            addToast({
                message:
                    error?.response?.data?.meta?.msg ||
                    error?.message ||
                    'Server Error !!!',
                type: 'info',
            });
        } finally {
            setLoading(false);
        }
    }

    const initialValues = {
        action_code: '',
        parent_id: '',
        next_follow_up: ''
    }


    const { handleSubmit, handleChange, handleBlur, setFieldValue, values, errors, touched, setFieldError, resetForm, } = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: Yup.object({
            action_code: Yup.string().required('Action code is required'),
            parent_id: Yup.string().required('Status code is required'),
            next_follow_up: Yup.string().required('Next follow up date is required'),
        }),
        onSubmit: values => {
            //   let newValues = values;
            //   if (!isNextFollowUpRequired) { newValues = { ...values, next_follow_up_date: '' }; }
            PostActionCode(values);
        },
    });


    return (
        <>
            <Box>
                <Breadcrumb
                    ml={2.5}
                    mt={4}
                    mb={4}
                    fontSize={!theme ? 'smaller' : 'lg'}
                    fontWeight={!theme ? 'normal' : 'semibold'}
                    spacing="8px"
                    separator={<MdKeyboardArrowRight color="gray.500" />}
                >
                    {/* <BreadcrumbItem>
                        <BreadcrumbLink
                            color="brand.500"
                            onClick={() => {
                                navigate('/task-list');
                            }}
                        >
                            Project Overview
                        </BreadcrumbLink>
                    </BreadcrumbItem> */}
                     <BreadcrumbItem>
                        <BreadcrumbLink
                            color="brand.500"
                            onClick={() => {
                                navigate('/projects');
                            }}
                        >
                            Project List
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <BreadcrumbLink
                            color="brand.500"
                            onClick={() => {
                                navigate('/add-status');
                            }}
                        >
                            Status Code
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem isCurrentPage>
                        <BreadcrumbLink
                            color={!theme ? 'primary.500' : 'gray.500'}
                            onClick={() => {
                                navigate('#');
                            }}
                        >
                            Add Action Code
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>
                <Box border="solid 1px" borderColor="gray.200" rounded="lg" bg="white.500">
                    <Heading p={3} borderBottom="solid 1px" borderColor="gray.200" fontSize="sm" color="brand.500">Action Code</Heading>
                    <form onSubmit={handleSubmit}>
                        <VStack p={8} spacing={6} >
                            <HStack width="70%" gap={8}>


                                <FormControl isInvalid={errors.action_code && touched.action_code} >
                                    <FormLabel>Action Code</FormLabel>
                                    <Flex alignItems='center' gap='2' >
                                        <Input
                                            placeholder="Enter action code"
                                            name="action_code"
                                            w='full'
                                            onChange={e => {
                                                setFieldValue('action_code', e?.target?.value)
                                            }}
                                            onBlur={handleBlur}
                                            value={values.action_code}
                                            fontSize='sm' fontWeight={500}
                                        />

                                    </Flex>
                                    <FormErrorMessage>{errors.action_code}</FormErrorMessage>
                                </FormControl>

                                <FormControl w='full' isInvalid={errors?.parent_id && touched?.parent_id}>
                                    <FormLabel >Status code</FormLabel>
                                    {/* <Select name="parent_id" w='full' value={values.parent_id} onChange={(e) => { */}
                                    <Select name="parent_id" w='full' value={values.parent_id} onChange={(e) => {
                                        // setIsNextFollowUpRequired(false);
                                        const intValue = parseInt(e.target.value)
                                        setFieldValue('parent_id', intValue);
                                        // setSelectedStatusCode(_statusCode?.find((data) => data?.statusCode === e?.target?.value)?.id);
                                    }} onBlur={handleBlur} fontSize='sm' fontWeight={500} >
                                        <option value='' >Select status code</option>
                                        {/* {_statusCode?.map((data, index) => <option key={data?.id} value={data?.statusCode}>{data?.statusCode || '---'}</option>)} */}
                                        {_statusCode?.map((data, index) => <option key={data?.id} value={parseInt(data?.id)}>{data?.statusCode || '---'}</option>)}
                                    </Select>
                                    <FormErrorMessage>{errors?.parent_id}</FormErrorMessage>
                                </FormControl>

                                {/* <FormControl isInvalid={errors.status_code && touched.status_code} >
                                    <FormLabel>Status Code</FormLabel>
                                    <Flex alignItems='center' gap='2' >
                                        <Input
                                            placeholder="Enter status code"
                                            name="status_code"
                                            w='full'
                                            onChange={e => {
                                                setFieldValue('status_code', e?.target?.value)
                                            }}
                                            onBlur={handleBlur}
                                            value={values.status_code}
                                            fontSize='sm' fontWeight={500}
                                        />

                                    </Flex>
                                    <FormErrorMessage>{errors.status_code}</FormErrorMessage>
                                </FormControl> */}

                            </HStack>

                            <HStack width="70%" gap={8}>
                                <FormControl isInvalid={errors.next_follow_up && touched.next_follow_up} >
                                    <FormLabel>Next Follow Up Date</FormLabel>
                                    <Flex alignItems='center' gap='2' >
                                        {/* <Input
                                            placeholder="Enter Next Follow Up Date"
                                            name="next_follow_up"
                                            w='full'
                                            onChange={e => {
                                                setFieldValue('next_follow_up', e?.target?.value)
                                            }}
                                            onBlur={handleBlur}
                                            value={values.next_follow_up}
                                            fontSize='sm' fontWeight={500}
                                        /> */}
                                        <Select name='next_follow_up' w='full' value={values.next_follow_up}
                                            onChange={e => {
                                                setFieldValue('next_follow_up', e?.target?.value)
                                            }}
                                            onBlur={handleBlur}
                                            value={values.next_follow_up}
                                            fontSize='sm' fontWeight={500}
                                        >
                                            <option value='' >Select Next Follow Up Date</option>
                                            <option value='true' >True</option>
                                            <option value='false' >False</option>

                                        </Select>

                                    </Flex>
                                    <FormErrorMessage>{errors.next_follow_up}</FormErrorMessage>
                                </FormControl>

                            </HStack>
                        </VStack>
                        <Flex p={3} borderTop="solid 1px" borderColor="gray.200" fontSize="sm" color="brand.500" justifyContent="end">
                            <HStack>
                                <Button
                                    isLoading={loading}
                                    loadingText="Submitting..."
                                    type="submit"
                                    // onClick={goToNextStep}
                                    _hover={{ bg: !theme ? 'primary.400' : 'moderntheme.500' }}
                                    bg={!theme ? "primary.500" : 'moderntheme.500'}
                                    color="white.500"
                                    py={2}
                                    height="auto"
                                    fontSize={12}
                                >
                                    Save
                                </Button>
                                {/* <Button isDisabled={loading} py={2} height="auto" fontSize={12} onClick={() => { resetForm(); }}>Cancel</Button> */}
                            </HStack>
                        </Flex>

                    </form>
                </Box>
            </Box>

        </>
    )
}

export default ActionCode
