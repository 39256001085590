import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import Config from '../lib/Config/Config';
import axios from 'axios';
import { CustomToast } from '../components/Common/Toast';


export const Defaults = () => {
    const { addToast } = CustomToast();
    const exportToCSV = (csvData, fileName) => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }
    const logout_api = async () => {
        try {
            const response = await axios.get(Config.Logout);
            if (response.status === 200) {
                sessionStorage.clear();
                window.location.replace('/login');
                console.log("logout_api", response?.data?.data);
                addToast({
                    message: response?.data?.data || "Logout Successfully",
                    type: 'success'
                });
            }
        } catch (error) {
            console.log('logout');
            addToast({
                message: error?.response?.data?.data || "Server error",
                type: 'info',
            });
            // window.location.replace('/Login');
        }
    }
   
    const clearSessionStorage = () => {
        sessionStorage.clear();
        window.location.replace('/login');
    }

    return { exportToCSV, logout_api, clearSessionStorage };
}




