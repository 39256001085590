
const Config = {
  Login: `${process.env.React_App_Base_Url}user/login`,
  Add_Project: `${process.env.React_App_Base_Url}project/create_project`,
  Upload_CSV: `${process.env.React_App_Csv_Url}upload`,
  get_project_list: `${process.env.React_App_Base_Url}project/get_project_list`,
  Get_Roles_List: `${process.env.React_App_Base_Url}role/allrole`,
  Get_Config_List: `${process.env.React_App_Base_Url}config/get_config_list`,
  Get_Client_List: `${process.env.React_App_Base_Url}client/get_client_list`,
  AddRole: `${process.env.React_App_Base_Url}role/addrole`,
  Get_Manager_List: `${process.env.React_App_Base_Url}user/get_managers`,
  Get_Employee_List_by_projectid: `${process.env.React_App_Base_Url}employee/get_project_employees/`,
  Get_Claim_List_by_projectid: `${process.env.React_App_Base_Url}claim/get_claims/`,
  Get_Departments: `${process.env.React_App_Base_Url}empolyee/Department/fatch_department`,
  Get_Me: `${process.env.React_App_Base_Url}user/get_me`,
  Add_Member: `${process.env.React_App_Base_Url}user/create_user`,
  Get_Member_List: `${process.env.React_App_Base_Url}employee/get_employee_list`,
  Update_Project_Priority: `${process.env.React_App_Base_Url}project/update_priority_request`,
  Logout: `${process.env.React_App_Base_Url}user/logout`,
  Get_Projects_By_Client_ID: `${process.env.React_App_Base_Url}project/get_projects_by_client_id/`,
  Create_Client: `${process.env.React_App_Base_Url}client/create_client`,
  Get_All_Department: `${process.env.React_App_Base_Url}empolyee/Department/all`,
  Create_Department: `${process.env.React_App_Base_Url}empolyee/Department/create`,
  Get_Employees_By_ProjectID_CreatedByID: `${process.env.React_App_Base_Url}user/get_employees`,
  Dashboard_Stats: `${process.env.React_App_Base_Url}project/dashboard_stats`,
  Assign_Project_To_Employee: `${process.env.React_App_Base_Url}project_employee/assign_project_to_employee`,
  Update_Project_Status: `${process.env.React_App_Base_Url}project/update_project_status`,
  Delete_Member_List: `${process.env.React_App_Base_Url}user/delete_employee`,
  Get_Module_Mapping: `${process.env.React_App_Base_Url}module/get_module_mapping/`,
  Get_Client_Project_Total: `${process.env.React_App_Base_Url}client/get_client_project_total`,
  Update_Member_List: `${process.env.React_App_Base_Url}user/update_employee_role`,
  Put_Update_Employee_Status: `${process.env.React_App_Base_Url}user/update_employee_status`,
  Client_Project_Status: `${process.env.React_App_Base_Url}client/client_project_status`,
  Put_Update_Profile_Password: `${process.env.React_App_Base_Url}user/change_password`,
  Get_Employees_Under_ManagerId: `${process.env.React_App_Base_Url}employee/employeeCreatedByManager`,
  Post_Assign_Claims: `${process.env.React_App_Base_Url}claim/assignClaims`,
  Get_UnAssign_Claims: `${process.env.React_App_Base_Url}claim/getUnassignClaimCount`,
  Get_Employees_List_Based_On_Project: `${process.env.React_App_Base_Url}user/get_employees`,
  Get_Clients_By_Manager_ID: `${process.env.React_App_Base_Url}client/get_clients_by_manager_id/`,
  // Get_Clients_By_Supervisor_ID: `${process.env.React_App_Base_Url}client/get_clients_by_supervisor_id/`,
  Get_Projects_By_Employee_ID: `${process.env.React_App_Base_Url}project/get_projects_by_employee_id/`,
  Get_Claims_By_Employee_ID: `${process.env.React_App_Base_Url}claim/get_claims/`,
  Get_Claims_By_Employee_And_Project_ID: `${process.env.React_App_Base_Url}claim/get_claims_by_employee/`,
  Get_Claim_Status_Codes: `${process.env.React_App_Base_Url}claim/get_claim_status_codes`,
  Get_Claim_Action_Codes: `${process.env.React_App_Base_Url}claim/get_claim_action_codes/`,
  Put_Update_Claims: `${process.env.React_App_Base_Url}claim/update_claims/`,
  Get_Manager_And_Supervisor_List: `${process.env.React_App_Base_Url}employee/get_manager_list`,
  Get_Claim_History: `${process.env.React_App_Base_Url}claim/get_claim_history/`,
  Get_Project_Claims_By_Employee: `${process.env.React_App_Base_Url}claim/report/projectClaimsByEmp`,
  Get_Client_Project_Claim: `${process.env.React_App_Base_Url}claim/report/clientProjectClaim`,
  Get_Supervisor_List: `${process.env.React_App_Base_Url}user/get_supervisors`,
  Get_Admin_Employee_List: `${process.env.React_App_Base_Url}employee/get_admins_employee_list`,
  Get_Manager_Employee_List: `${process.env.React_App_Base_Url}employee/get_managers_employee_list`,
  Get_Supervisors_Employee_List: `${process.env.React_App_Base_Url}employee/get_supervisors_employee_list`,
  Put_ReAssign_Project_Supervisor: `${process.env.React_App_Base_Url}project/reassign_project_supervisor`,
  Get_Top_Status: `${process.env.React_App_Base_Url}claim/report/get_top_status`,
  Get_Claims_Stats: `${process.env.React_App_Base_Url}claim/claims_stats`,
  Put_Update_Employee: `${process.env.React_App_Base_Url}user/update_employee`,
  Get_Project_Claims_Stats_By_Client_ID: `${process.env.React_App_Base_Url}claim/get_project_claims_stats_by_client_id`,
  Get_Employee_Claims_Stats_By_Project_ID: `${process.env.React_App_Base_Url}claim/get_employee_claims_stats_by_project_id`,
  Put_Update_Project: `${process.env.React_App_Base_Url}project/update_project_details`,
  Put_Delete_Project: `${process.env.React_App_Base_Url}project/delete_project`,
  Get_Ip_Address: `${process.env.React_App_Base_Url}ipAddress/get_ip`,
  Post_Reset_Password: `${process.env.React_App_Base_Url}reset/generate_reset_token`,
  Put_Reset_Password_Tokken: `${process.env.React_App_Base_Url}reset/reset_password_By_token`,
  Get_Aging_Count: `${process.env.React_App_Base_Url}claim/get_count_aging_by_project_id/`,
  Post_Create_Ticket: `${process.env.React_App_Base_Url}ticket/raise_ticket`,
  Get_Tickets: `${process.env.React_App_Base_Url}ticket/get_tickets`,
  Get_Tickets_By_Employee_Id: `${process.env.React_App_Base_Url}ticket/get_tickets_by_employee_id`,
  Get_Ticket_Details_By_Id: `${process.env.React_App_Base_Url}ticket/get_ticket_details_by_id`,

  Get_App_Version: `${process.env.React_App_Base_Url}version/get_version`,
  Put_Update_Ticket: `${process.env.React_App_Base_Url}ticket/update_ticket`,
  Get_Employee_status_count: `${process.env.React_App_Base_Url}user/get_user_status_count`,
  Get_Top_Employee: `${process.env.React_App_Base_Url}claim/get_monthly_employee_performance_count`,
  Get_Projects: `${process.env.React_App_Base_Url}project/get_projects_overview`,
  Get_Touched_Project_Claim_Count: `${process.env.React_App_Base_Url}project/get_project_claims_count`,
  Get_Overall_Claims: `${process.env.React_App_Base_Url}claim/get_overall_claims_stats`,
  Get_Overall_Project: `${process.env.React_App_Base_Url}project/get_overall_projects_stats`,
  Get_Claim_Stat_For_Project: `${process.env.React_App_Base_Url}claim/get_claims_stats_for_projects`,
  Get_Client_List_For_Dashboard: `${process.env.React_App_Base_Url}client/client_list_for_dashboard`,
  Get_Projects_Overview: `${process.env.React_App_Base_Url}project/get_projects_overview`,
  Get_Employee_Productivity_Report: `${process.env.React_App_Base_Url}claim/employee_productivity_report`,
  Get_Employee_Productivity_Report_By_Hr: `${process.env.React_App_Base_Url}claim/employee_productivity_report_by_hr`,
  Get_Employee_Monthly_productivity: `${process.env.React_App_Base_Url}claim/monthly_employee_productivity_report`,
  Get_Employee_Today_productivity: `${process.env.React_App_Base_Url}claim/employee_todays_productivity`,
  Get_Employee_Today_productivitydata: `${process.env.React_App_Base_Url}claim/employee_todays_productivitydata`,
  Get_Employee_Projects_list_by_emloyeeid: `${process.env.React_App_Base_Url}project/get_project_claims_count_by_employee_id`,
  Get_Graph_Claims_Stats: `${process.env.React_App_Base_Url}claim/graph_claims_stats`,
  Get_Ticket_Supervisor_Count: `${process.env.React_App_Base_Url}ticket/get_ticket_supervisor_count`,
  Post_Status_Code: `${process.env.React_App_Base_Url}code/add_status_code`,
  Post_Action_Code: `${process.env.React_App_Base_Url}code/add_action_code`,
}

export default Config
