import axios from 'axios';
import React, { createContext, useEffect, useLayoutEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Config from '../Config/Config';
import { CustomToast } from '../../components/Common/Toast';

const Verifycontext = createContext();

function useSessionState(key, defaultValue = null) {
    const value = sessionStorage.getItem(key);
    return useState(value ? JSON.parse(value) : defaultValue);
}

function LoginVeification({ children }) {
    const token = sessionStorage.getItem('token');
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const [rolesdata, setRolesdata] = useSessionState('roles');
    const [departmentdata, setDepartmentdata] = useSessionState('department');
    const [me, setMe] = useSessionState('me');
    const [module, setModule] = useSessionState('module');
    const [loading, setLoading] = useState(false);
    const { addToast } = CustomToast();
    const admin_access = rolesdata?.find((data) => data?.role_name === 'admin')?.id === me?.role_id;
    const superviser_access = rolesdata?.find((data) => data?.role_name === 'supervisor')?.id === me?.role_id;
    const manager_access = rolesdata?.find((data) => data?.role_name === 'manager')?.id === me?.role_id;
    const employee_access = rolesdata?.find((data) => data?.role_name === 'employee')?.id === me?.role_id;
    const client_access = rolesdata?.find((data) => data?.role_name === 'client')?.id === me?.role_id;

    if (token && (pathname !== "/login" || pathname !== "/reset/password")) {
        axios.interceptors.response.use(
            (response) => response,
            (error) => {
                console.log('error',error);
                // Handle errors here, you can log them or display a message to the user.
                if (error?.response?.data?.meta?.statusCode === 401 || error?.response?.data?.data?.statusCode === 401) {
                    addToast({
                        message: 'Session expired',
                        type: 'info',
                    });
                    sessionStorage.clear();
                    window.location.replace('/login');
                }
                else {
                    console.log(error?.response?.data?.data?.msg ||error?.response?.data?.meta?.msg || 'Something went wrong');
                    // addToast({
                    //     message: error?.response?.data?.data?.msg || 'Something went wrong',
                    //     type: 'info',
                    // });
                }
                // // Rethrow the error to propagate it to the component making the request
                throw error;
            }
        );
    }

    useLayoutEffect(() => {
        if (!token && pathname === '/reset/password') {
            // window.location.replace('/reset/password');    
        } else if (!token && pathname !== "/login") {
            sessionStorage.clear();
            window.location.replace('/login');
        } else if (token && pathname === "/login") {
            window.location.replace('/dashboard');
        }
    }, [navigate, token])

    const fetchData = async (url, setDataCallback, storageKey, method = 'get') => {
        try {
            const response = await axios[method](url);
            if (response.status === 200) {
                setDataCallback(response?.data?.data);
                sessionStorage.setItem(storageKey, JSON.stringify(response?.data?.data));
            }
        } catch (error) {
            console.error('Error_fetching_data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!me && pathname !== "/login") {
            fetchData(Config.Get_Me, setMe, 'me', 'post'); // Use POST method for fetchMeData
        }
        if (!module && me && pathname !== "/login") {
            setLoading(true);
            fetchData(`${Config?.Get_Module_Mapping}${me?.role_id}`, setModule, 'module');
        }
        if (!rolesdata && pathname !== "/login") {
            fetchData(Config?.Get_Roles_List, setRolesdata, 'roles');
        }
        if (!departmentdata && pathname !== "/login") {
            fetchData(Config?.Get_Departments, setDepartmentdata, 'department');
        }

    }, [rolesdata, departmentdata, me, module]);


    return (
        <Verifycontext.Provider value={{ Rolelist: rolesdata, DepartmentList: departmentdata, Medata: me, Module: module, loading: loading, admin_access: admin_access, superviser_access: superviser_access, manager_access: manager_access, employee_access: employee_access,client_access:client_access }}>
            {children}
        </Verifycontext.Provider>
    )
}

export { Verifycontext, LoginVeification }