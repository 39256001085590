import React from 'react'
import { motion } from "framer-motion";
function SlideRightToLeft({ children }) {
    return (
        <motion.div
            initial={{ x: "100%" }} // Start off the screen on the right
            animate={{ x: 0 }} // Animate to the center
            exit={{ x: "-100%" }} // Animate off to the left
            transition={{ duration: 1.6, type: "spring" }} // Adjust duration and easing as needed
        // style={{ height: 'auto' }}
        >
            {children}
        </motion.div>
    )
}

export default React.memo(SlideRightToLeft);