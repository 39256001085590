import { useToast } from "@chakra-ui/react";

export const CustomToast = () => {
    const toast = useToast();
    const id = 'test-toast';
    // types are: "success", "info", "warning", "error"

    const addToast = (newRes) => {
        if (!toast.isActive(id)) {
            toast({
                id,
                title: newRes?.title,
                description: newRes.message || '',
                status: newRes.type || 'info',
                position: newRes.position || "top-right",
                isClosable: true,
                duration: newRes.duration || 5000,
                variant: 'top-accent',
            })
        }
    }

    return { addToast };
}