import axios from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';
import Config from '../Config/Config';
import { Verifycontext } from './LoginVeification';

const SearchprojectContext = createContext();

const SearchprojectProvider = ({ children }) => {
    const { Medata } = useContext(Verifycontext);
    const EmployeeID = Medata?.userId || '';
    const RoleID = Medata?.role_id || '';
    const [ProjectId, setProjectId] = useState(null);
    const [projectList, setProjectList] = useState(null);
    const [projectName, setProjectName] = useState('');
    const [clientProjectStatus, setClientProjectStatus] = useState('active');
    const Get_Projects_By_Employee_ID = async search => {
        if (!EmployeeID) return;
        try {
            let url = RoleID == 5 ? `${Config.Get_Projects_By_Client_ID}${EmployeeID}` : `${Config.Get_Projects_By_Employee_ID}${EmployeeID}`;

            const response = await axios.get(
                url,
                {
                    params: {
                        projectStatus: 'active',
                    },
                }
            );
            if (response?.status === 200) {
                if(RoleID == 5){
                    setProjectList(response?.data?.data.projects);
                    if (response?.data?.data.projects) {
                        if (response?.data?.data.projects[0]?.id) {
                            setProjectId(response?.data?.data.projects[0]?.id);
                            setProjectName(response?.data?.data.projects[0]?.project_name);
                        }
                    }
                }else{
                    setProjectList(response?.data?.data);
                    if (response?.data?.data) {
                        if (response?.data?.data[0]?.id) {
                            setProjectId(response?.data?.data[0]?.id);
                            setProjectName(response?.data?.data[0]?.project_name);
                        }
                    }
                }
              
            }
        } catch (error) {
            console.log('response error', error?.message);
            setProjectList([])
        }
    };

    useEffect(() => {
        Get_Projects_By_Employee_ID();
    }, [EmployeeID]);


    return (
        <SearchprojectContext.Provider value={{ projectName, projectList, ProjectId, setProjectName, setProjectList, setProjectId }}>
            {children}
        </SearchprojectContext.Provider>
    )
}

export { SearchprojectContext, SearchprojectProvider };
