// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .spinner {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    border: 9px solid;
    border-color: #dbdcef;
    border-right-color: #474bff;
    animation: spinner-d3wgkg 0.3s infinite linear;
 }
 
 @keyframes spinner-d3wgkg {
    to {
       transform: rotate(1turn);
    }
 }
 */
.spinner {
   width: 40px;
   height: 40px;
   border-radius: 50%;
   background: radial-gradient(farthest-side, #474bff 94%, #0000) top/6.4px 6.4px no-repeat,
      conic-gradient(#0000 30%, #474bff);
   -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 6.4px), #000 0);
   animation: spinner-c7wet2 0.3s infinite linear;
}

@keyframes spinner-c7wet2 {
   100% {
      transform: rotate(1turn);
   }
}`, "",{"version":3,"sources":["webpack://./src/styles/spinner.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;EAeE;AACF;GACG,WAAW;GACX,YAAY;GACZ,kBAAkB;GAClB;wCACqC;GACrC,8EAA8E;GAC9E,8CAA8C;AACjD;;AAEA;GACG;MACG,wBAAwB;GAC3B;AACH","sourcesContent":["/* .spinner {\n    width: 56px;\n    height: 56px;\n    border-radius: 50%;\n    border: 9px solid;\n    border-color: #dbdcef;\n    border-right-color: #474bff;\n    animation: spinner-d3wgkg 0.3s infinite linear;\n }\n \n @keyframes spinner-d3wgkg {\n    to {\n       transform: rotate(1turn);\n    }\n }\n */\n.spinner {\n   width: 40px;\n   height: 40px;\n   border-radius: 50%;\n   background: radial-gradient(farthest-side, #474bff 94%, #0000) top/6.4px 6.4px no-repeat,\n      conic-gradient(#0000 30%, #474bff);\n   -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 6.4px), #000 0);\n   animation: spinner-c7wet2 0.3s infinite linear;\n}\n\n@keyframes spinner-c7wet2 {\n   100% {\n      transform: rotate(1turn);\n   }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
