import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar';
// import { NewThemeContext } from '../../lib/Providers/ThemeSwitch';

const LoadingTopBar = () => {
    const ref = useRef(null);
    const [progress, setProgress] = useState(0);
    const location = useLocation();
    // const { theme } = useContext(NewThemeContext);
    // console.log('theme', theme);
    useEffect(() => {
        let requestCount = 0;
        const axiosInterceptor = axios.interceptors.request.use((config) => {
            requestCount++;
            ref?.current?.continuousStart()
            return config;
        });
        const axiosResponseInterceptor = axios.interceptors.response.use(
            (response) => {
                requestCount--;
                if (requestCount === 0) {
                    ref?.current?.complete();
                }
                return response;
            },
            (error) => {
                requestCount--;
                // If there are no more ongoing requests, stop the loading bar
                if (requestCount === 0) {
                    ref?.current?.complete();
                }
                throw error;
            }
        );
        return () => {
            axios.interceptors.request.eject(axiosInterceptor);
            axios.interceptors.response.eject(axiosResponseInterceptor);
        }
    }, [location.pathname]);
    return (
        // #4168F5
        <LoadingBar
            ref={ref}
            progress={progress}
            height={3}
            // color={theme ? "#00B4B4" : '#4168F5'}
            color={'#00B4B4'}
            shadow='true'
            onLoaderFinished={() => setProgress(0)}
        />
    )
}

export default React.memo(LoadingTopBar)