import { useState } from 'react'
import axios from 'axios';
import Config from '../lib/Config/Config';
import { CustomToast } from '../components/Common/Toast';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import useAppVersion from './useAppVersion';
/**
 * Custom hook for handling login functionality in a React application.
 * @returns {Object} An object containing values and functions for the login component.
 */
const useLogin = () => {
    const { appVersion } = useAppVersion();
    const { addToast } = CustomToast();
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const initialValues = {
        email: '',
        password: '',
    };

    /**
     * Handles the login logic.
     * @param {Object} loginObject - The login object containing email and password.
     */
    const makeLogin = async (loginObject) => {
        try {
  console.log(Config.Login);
            const res = await axios.post(Config.Login, loginObject);

            if (res.status === 200) {
                window.sessionStorage.setItem('token', res?.data?.data?.token);
                axios.defaults.headers.common.Authorization = `Bearer ${res?.data?.data?.token}`;
                addToast({
                    message: 'Login Successfully',
                    type: 'success',
                });
                window.location.href = '/dashboard';
            }
        } catch (err) {
            const message = err?.response?.data?.message || err?.response?.data?.data?.msg || 'Server Error !!!';
            addToast({
                message: message,
                type: 'info',
            });
        } finally {
            setLoading(false);
        }
    };

    const { handleSubmit, values, errors, touched, handleChange, handleBlur } = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: Yup.object({
            email: Yup.string().email('Enter Valid email ').min(6).required('Email is required'),
            password: Yup.string().required('Password is required'),
        }),
        onSubmit: (values) => {
            setLoading(true);
            const loginObject = {
                email: values.email,
                password: values.password,
            };
            setLoading(true);
            makeLogin(loginObject);
        },
    });

    return {
        loading,
        handleSubmit,
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        appVersion,
        showPassword,
        setShowPassword,
    };
};

export default useLogin
