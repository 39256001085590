import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Box, Button, ButtonGroup, Divider, Flex, Icon, IconButton, Input, InputGroup, InputLeftElement, InputRightElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Tag, Text, useDisclosure } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react'
import { AiOutlineSearch } from 'react-icons/ai';
import { HiOutlineMenuAlt2 } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { Verifycontext } from '../../lib/Providers/LoginVeification';
import { NewThemeContext } from '../../lib/Providers/ThemeSwitch';
function GlobalMenuSearchPopup() {
    const { theme } = useContext(NewThemeContext);
    // NavbarSearchIsOpen, NavbarSearchOnOpen, NavbarSearchOnClose
    // const { NavbarSearchIsOpen, NavbarSearchOnOpen, NavbarSearchOnClose } = useContext(Verifycontext);
    const { isOpen: NavbarSearchIsOpen, onOpen: NavbarSearchOnOpen, onClose: NavbarSearchOnClose } = useDisclosure();
    const { Module } = useContext(Verifycontext);

    const navigate = useNavigate();
    const [isFocused, setIsFocused] = useState(false);
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [moduleNvaigation, setModuleNvaigation] = useState([]);

    const handleInputSearch = (event) => {
        const query = event?.target?.value?.toLowerCase();
        setSearchQuery(query);
        if (query === '') {
            setFilteredSuggestions([]);
        } else {
            // Filter suggestions based on the search query
            const filtered = moduleNvaigation?.filter((item) => item?.name?.toLowerCase()?.includes(query));
            setFilteredSuggestions(filtered);
        }
    };



    const handleInputKeyPress = (e) => {

        // if (e.key === 'Enter' && filteredSuggestions.length > 0) {
        //     // try {
        //     setFilteredSuggestions([])
        //     NavbarSearchOnClose();
        //     setSearchQuery('')
        //     // } finally {
        //     const first_url = filteredSuggestions[0]?.url;
        //     navigate(first_url);
        //     // }
        // }
        // else
        if (e.key === 'Escape') {
            // setIsFocused(false);
            NavbarSearchOnClose();
            setSearchQuery('');
            setFilteredSuggestions([]);
        }
    };


    useEffect(() => {
        if (Module) {
            const a = Module?.map((data) => ({ name: data?.name, url: data?.url, read_access: data?.read_data }))?.filter((data) => data?.url !== '' && data?.read_access === true);
            const b = Module?.map((data) => data?.child)?.flatMap((arr) => arr)?.map((data) => ({ name: data?.name, url: data?.url, read_access: data?.read_data }))?.filter((data) => data?.read_access === true);;
            if (a && b) {
                const ModuleFilter = [...a, ...b];
                setModuleNvaigation(ModuleFilter);
            }
            // sessionStorage.setItem('moduleNvaigation', JSON.stringify(ModuleFilter));
        }
    }, [Module]);

    const handle12change = (url) => {
        NavbarSearchOnClose();
        setSearchQuery('');
        setFilteredSuggestions([]);
        navigate(url);
    }

    const handleCtrlKShortcut = (event) => {
        // Check if the key combination is Ctrl + K
        if (event.ctrlKey && event.key === 'k') {
            // Prevent the default Ctrl + K behavior (e.g., opening browser dev tools)
            event.preventDefault();

            // Focus on the search input
            NavbarSearchOnOpen()
        }
    };

    useEffect(() => {
        // Add the global keydown event listener
        window.addEventListener('keydown', handleCtrlKShortcut);

        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener('keydown', handleCtrlKShortcut);
        };
    }, []);


    return (
        <>
            <Flex>
                <Button
                    variant='unstyled'
                    transition='.3s'
                    _hover={{ bg: 'transparent', border: '1px', borderColor: !theme ? 'primary.500' : '#00b4b4' }}
                    bg='white'
                    rounded='full'
                    border='1px'
                    borderColor='gray.300'
                    gap='4'
                    onClick={NavbarSearchOnOpen}
                    alignItems='center'
                    mx='1'
                    px='4'
                    cursor='pointer'
                    width={200}
                    fontSize={12}
                >
                    <Flex alignItems='center' gap='2'>
                        <Icon as={AiOutlineSearch} />
                        <Text mr='8' fontWeight='medium' size='md' color='gray.500' bg='transparent'>Search</Text>
                        <Tag size={'sm'}>Ctrl</Tag>
                        <Tag size={'sm'}>K</Tag>
                    </Flex>
                </Button>
                {
                    NavbarSearchIsOpen &&
                    <Modal
                        onEsc={() => { NavbarSearchOnClose(); }}
                        motionPreset='slideInRight'
                        onCloseComplete={() => {
                            setFilteredSuggestions([]);
                            setSearchQuery('');
                        }}
                        // finalFocusRef={finalRef}
                        isOpen={NavbarSearchIsOpen}
                        onClose={NavbarSearchOnClose}
                    >
                        <ModalOverlay />
                        <ModalContent >
                            {/* <ModalHeader>Modal Title</ModalHeader> */}
                            <ModalBody
                                bg='gray.100'
                                rounded='md'
                            >
                                <Flex h='50px' rounded='md' gap='2' alignItems='center'>
                                    <Icon h='full' fontWeight='semibold' as={AiOutlineSearch} />
                                    <Input h='full' fontWeight='semibold' fontSize='xl' color='gray.800' variant='unstyled' onKeyDown={handleInputKeyPress} value={searchQuery} onChange={(e) => { handleInputSearch(e); }} type="text" placeholder="Find a Page" />
                                </Flex>
                                <Flex
                                    my={filteredSuggestions.length > 0 ? '4' : '0'}
                                    className='scroll_port'
                                    rounded='md'
                                    maxH='60vh'
                                    overflowY='auto'
                                    bg='gray.100'
                                    flexDirection='column'
                                    w='full'
                                    gap='2'
                                >
                                    {filteredSuggestions?.map((data, i) => {
                                        return (
                                            <>
                                                <Box bg='gray.200' py='1' rounded='md' px='2' mx='2' key={data?.url} onClick={() => { handle12change(data?.url) }}>
                                                    <Text py="2" px='1' fontSize='small' cursor='pointer' >{data?.name}</Text>
                                                </Box>
                                            </>
                                        )
                                    })}
                                    {filteredSuggestions?.length === 0 && searchQuery !== ''
                                        &&
                                        <>
                                            <Box bg='gray.200' py='1' rounded='md' px='2' mx='2' key='no_data_available'>
                                                <Text py="2" px='1' fontSize='small' fontWeight='semibold' color='gray.500' cursor='pointer' >No Data Available</Text>
                                            </Box>
                                        </>
                                    }
                                </Flex>
                            </ModalBody>

                            {/* <ModalFooter>
                            <Button colorScheme='blue' mr={3} onClick={onClose}>
                                Close
                            </Button>
                            <Button variant='ghost'>Secondary Action</Button>
                        </ModalFooter> */}
                        </ModalContent>
                    </Modal>
                }
            </Flex >
        </>
    )
}

export default React.memo(GlobalMenuSearchPopup)