import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay, 
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { CustomToast } from '../Common/Toast';

const DeletePopup = ({ isOpen, onClose, deleteMember, handleStatus, cancel, content }) => {
  // const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = React.useRef();
  const { addToast } = CustomToast();

  return (
    <>
      {/* <Button colorScheme='red' onClick={onOpen}>
          Delete Customer
        </Button>
   */}
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent bg="white.500">
            <AlertDialogHeader fontSize="lg" fontWeight="bold" >
              {content?.heading}
            </AlertDialogHeader>

            <AlertDialogBody>
              {content?.message}
              </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                {cancel}
              </Button>
              <Button
                colorScheme={content?.color}
                onClick={() => {
                  handleStatus();
                  // deleteMember();
                  onClose();
                }}
                ml={3}
              >
                {content?.submitButton}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
export default React.memo(DeletePopup);
