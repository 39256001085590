import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import React, { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import { myNewTheme } from './CustomTheme';
// import { myNewTheme } from './CustomTheme';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
  <ChakraProvider theme={myNewTheme}>
    {/* <StrictMode> */}
    <ColorModeScript />
    <App />
    {/* </StrictMode> */}
  </ChakraProvider>
);

serviceWorker.unregister();
reportWebVitals();
