import styled from '@emotion/styled';
export const GoContainerBox = styled.div` 
width: 100%;
background-clip: padding-box;
box-shadow: 0 0.125rem 0.625rem 0 rgba(76,78,100,.22);
// height: 100%;
color:#636578;
background: #fff;
// padding: 1.375rem 1.25rem;
border-radius: 0.625rem
`;
const TableStyles = {
    global: {
      ".resizer": {
        position: "absolute",
        opacity: 0,
        top: 0,
        right: 0,
        h: "100%",
        w: "5px",
        bg: "#27bbff",
        cursor: "col-resize",
        userSelect: "none",
        touchAction: "none",
        borderRadius: "6px",
      },
      ".resizer.isResizing": {
        bg: "#2eff31",
        opacity: 1,
      },
      "*:hover > .resizer": {
        opacity: 1,
      },
    },
  };
  
  export default TableStyles;
  