import { Button } from '@chakra-ui/button'
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/menu'
import { Tooltip } from '@chakra-ui/tooltip'
import React, { useContext } from 'react'
import { AiOutlineFileAdd } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { Verifycontext } from '../../lib/Providers/LoginVeification'

export const CreateMenu = () => {
    const navigate = useNavigate();
    const { admin_access, manager_access, superviser_access } = useContext(Verifycontext);
    return (
        <>
            {(admin_access || manager_access || superviser_access) &&
                <>
                    <Menu>
                        <Tooltip fontSize='sm' label="Create Entity">
                            {/* <IconButton color='primary.500' aria-label='Search database' icon={<AiOutlineEdit />} /> */}
                            {/* <AiOutlineEdit color='primary.500' cursor='pointer' /> */}
                            <MenuButton
                                height={8}
                                _active={{ bg: 'none' }}
                                bg='none'
                                _hover={{ bg: 'none' }}
                                color='primary.500'
                                border='1px'
                                borderColor='primary.500'
                                fontSize='sm' rounded={'full'} as={Button} leftIcon={<AiOutlineFileAdd />}>
                                Create
                            </MenuButton>
                        </Tooltip>
                        <MenuList fontSize={13} minW='8rem'>
                            {admin_access &&
                                <>
                                    {/* <MenuItem minH='35px' onClick={() => { navigate('/add-role') }}>
                                        <span>Add Role</span>
                                    </MenuItem> */}
                                    <MenuItem minH='35px' onClick={() => { navigate('/add-project') }}>
                                        <span>Add Project</span>
                                    </MenuItem>
                                    <MenuItem minH='35px' onClick={() => { navigate('/add-client') }}>
                                        <span>Add Client</span>
                                    </MenuItem>
                                    <MenuItem minH='35px' onClick={() => { navigate('/add-department') }}>
                                        <span>Add Department</span>
                                    </MenuItem>
                                    <MenuItem minH='35px' onClick={() => { navigate('/add-employee') }}>
                                        <span>Add Employee</span>
                                    </MenuItem>
                                </>
                            }
                            {
                                manager_access &&
                                <>
                                    <MenuItem minH='35px' onClick={() => { navigate('/add-employee') }}>
                                        <span>Add Employee</span>
                                    </MenuItem>
                                    <MenuItem minH='35px' onClick={() => { navigate('/add-project') }}>
                                        <span>Add Project</span>
                                    </MenuItem>
                                    <MenuItem minH='35px' onClick={() => { navigate('/add-client') }}>
                                        <span>Add Client</span>
                                    </MenuItem>
                                </>
                            }
                            {
                                superviser_access &&
                                <MenuItem minH='35px' onClick={() => { navigate('/add-employee') }}>
                                    <span>Add Employee</span>
                                </MenuItem>
                            }
                        </MenuList>
                    </Menu>
                </>
            }
        </>
    )
}
